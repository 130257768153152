import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { duplicatePayrollTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions"; 
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const DuplicateDialog = (props) => {

  const { open, data, types, handleClose } = props;

  const [ code, setCode ] = useState("");
  const [ name, setName ] = useState("")
  const [ type, setType ] = useState(data.type)

  const dispatch = useDispatch();

  const dataIsValid = () => {
    const codeRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    
    if(!code || code === "" || !codeRegex.test(code)) return false;
    if(!name || name === "") return false;
    if(!type || type === "") return false;
    return true;
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const handleSave = () => {
    if(!dataIsValid()){      
      openValidationError();
      return;
    }    

    const dataToSend = { originalCode: data.code, code, name, type }
    
    dispatch(duplicatePayrollTypeAction(dataToSend))
    .then((resp) => {
      if(resp.status === 200){
        handleClose();
      }
    })
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">  
        {"Duplicar tipo de nómina"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} direction="column">
          <Typography className="whiteText" style={{ fontSize: "18px", fontWeight: 400 }}>
            {"Por favor, ingrese el código y nombre del tipo de nómina. Estos datos reemplazarán los originales."}
          </Typography>          
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* codigo */}
            <TextField
              required
              fullWidth
              margin={"none"}            
              label={"Codigo del tipo de nómina"}
              onChange={(e) => setCode(e.target.value)}
              value={code}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
            <TextField            
              required
              fullWidth
              margin={"none"}            
              label={"Nombre del tipo de nómina"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>  
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* types */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-types-${data.code}`}>{"Tipo"}</InputLabel>
              <Select        
                value={type}
                labelId={`label-types-${data.code}`}
                id={`select-types-${data.code}`}
                onChange={(e) => setType(e.target.value)}
                margin={"none"}
              >
                {
                  types && types.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>      
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSave} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
