import * as actionTypes from "./actionTypes";
import {
  getPayrollTypesSettingsAPI,
  deletePayrollTypesSettingsAPI,
  createPayrollTypesSettingsAPI,
  updatePayrollTypesSettingsAPI,
  duplicatePayrollTypeAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";

  if (e.response?.data?.status === "IN_USE_CONFIRM") {
    //si es de este error, no quiero que abra el Snackbar
    return;
  }

  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de un tipo de nómina que comparta el mismo código o nombre";
      break;
    case "IN_USE":
      errorString = "No es posible eliminar el tipo de nómina porque está en uso";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getPayrollTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getPayrollTypesSettingsAPI();

    let data = response.data && response.data.data;
    let types = response.data && response.data.typeList;
    let accumList = response.data && response.data.accumList;
    let processSelectionList = response.data && response.data.processSelectionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        types,
        accumList,
        processSelectionList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deletePayrollTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deletePayrollTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de nómina eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};

export const createPayrollTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createPayrollTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de nómina creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });

  }
};

export const modifyPayrollTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updatePayrollTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de nómina actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};

export const duplicatePayrollTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicatePayrollTypeAPI(dataToSend);
    let data = response.data && response.data.data;

    dispatch({ type: actionTypes.DUPLICATE_FULFILLED, payload: { data } });
    dispatch(openSnackbarAction({ msg: "Tipo de nómina duplicado con éxito", severity: "success" }));

    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    return e.response;
  }
};