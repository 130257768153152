import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPayrollTypeAction, modifyPayrollTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    types,
    accumList,
    processSelectionList,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createPayrollTypeAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (modifyData) => {
    const dataToSend = { ...modifyData, internalCode: data.internalCode };
    dispatch(modifyPayrollTypeAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Código"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-types-${data ? data.code : "new"}`}>{"Tipo"}</InputLabel>
            <Select
              value={formData.type}
              labelId={`label-types-${data ? data.code : "new"}`}
              id={`select-types-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                types?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-active-${data ? data.code : "new"}`}>{"Activar por selección"}</InputLabel>
            <Select
              value={formData.active}
              labelId={`label-active-${data ? data.code : "new"}`}
              id={`select-active-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "active")}
              margin={"none"}
            >
              {
                accumList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" sm={12} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-alloswMultiple`}>{"Permitir nóminas concurrentes de este mismo tipo"}</InputLabel>
            <Select
              value={formData.allowsMultiple}
              labelId={`label-allowsMultiple`}
              id={`select-allowsMultiple`}
              onChange={(e) => setFormValue(e.target.value, "allowsMultiple")}
              margin={"none"}
            >
              {
                [{ key: 'N', value: 'No' }, { key: 'Y', value: 'Si' }].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" sm={12} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-processSelection`}>{"Determinación de colaboradores habilitados"}</InputLabel>
            <Select
              value={formData.processSelection}
              labelId={`label-processSelection`}
              id={`select-processSelection`}
              onChange={(e) => setFormValue(e.target.value, "processSelection")}
              margin={"none"}
            >
              {
                processSelectionList
                  ?.filter((item) => {
                    // si el type es Otros, que no salga la opcion Autodeterminado
                    if (formData.type !== "O") return true;
                    return item.key !== "A";
                  })
                  ?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
