import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPayrollTypesAction } from "../actions";
import {
  getLoading,
  getData,
  getTypes,
  getAccumList,
  getProcessSelectionList,
} from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import paths from "@icarius-localization/paths";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const PayrollTypesSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const types = useSelector(getTypes);
  const accumList = useSelector(getAccumList);
  const processSelectionList = useSelector(getProcessSelectionList);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getPayrollTypesAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.conceptsAndFormulasScreenSelector);
  }

  return (
    <ABMLayout
      title={"Tipos de nómina"}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nuevo tipo de nómina"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron tipos de nómina"}
      noDataCreateTitle={"Nuevo tipo de nómina"}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            types={types}
            accumList={accumList}
            processSelectionList={processSelectionList}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem
                data={item}
                types={types}
                accumList={accumList}
                processSelectionList={processSelectionList}
                color={color}
                isLoading={isLoading}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default PayrollTypesSettings;