import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, FileCopyIcon } from "@icarius-icons";
import Form from "./form";
import { deletePayrollTypeAction } from "../../actions";
import DuplicateDialog from "./duplicateDialog";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const {
    data,
    types,
    accumList,
    processSelectionList,
    color,
    closeNew,
    isLoading,
  } = props;
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);

  const handleDeletePublication = (isConfirm) => {
    if (isCreate) {
      closeNew();
    } else {
      const dataToDelete = {
        internalCode: data.internalCode,
        code: data.code,
        name: data.name,
        type: data.type,
        allowsMultiple: data.allowsMultiple,
        confirm: isConfirm,
      };

      dispatch(deletePayrollTypeAction(dataToDelete))
        .then((resp) => {
          if (resp.data.status === "IN_USE_CONFIRM") {
            dispatch(openDialogAction({
              title: "Atención",
              msg: "Si elimina este tipo de nómina, todos los conceptos y todos los colaboradores que estén relacionados con este tipo de nómina serán deshabilitados de la misma. ¿Desea continuar?",
              acceptText: "Aceptar",
              onConfirm: () => handleDeletePublication(true),
            }));
          }
        })
    }
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const getTypeName = () => {
    return types.filter(type => type.key === data.type)[0].value;
  }

  const getCodeName = () => {
    const name = processSelectionList.filter(type => type.key === data.processSelection)[0].value;
    if (name) {
      return `${data.code} - ${name}`;
    }

    return data.code;
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: "Eliminar tipo de nómina",
      msg: "¿Desea eliminar el tipo de nómina?",
      onConfirm: () => handleDeletePublication(false),
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={"Creación de acumulador"} />
                :
                <>
                  <DataContainer sm={12} md={4} lg={4} text={data.name} />
                  <DataContainer sm={12} md={5} lg={5} text={getCodeName()} />
                  <DataContainer sm={12} md={3} lg={3} text={getTypeName()} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={2} justify={isCreate ? "flex-end" : "space-between"}>
            {
              !isCreate &&
              <Tooltip title={"Duplicar tipo de nómina"}>
                <IconButton size={"small"} disableRipple onClick={() => setDuplicateIsOpen(true)}>
                  <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          types={types}
          accumList={accumList}
          processSelectionList={processSelectionList}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
      {
        duplicateIsOpen && !isLoading &&
        <DuplicateDialog
          open={duplicateIsOpen}
          data={data}
          types={types}
          handleClose={() => setDuplicateIsOpen(false)}
        />
      }
    </>
  );
}

export default DataItem;
