import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'allowsMultiple') {
        return (isCreate || !data[fieldName]) ? "N" : data[fieldName];
      }

      if (fieldName === 'processSelection') {
        return (isCreate || !data[fieldName]) ? "A" : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "type",
      "active",
      "allowsMultiple",
      "processSelection",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    const { code, name, type, processSelection } = formData;

    const codeRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;

    if (!code || code === "" || !codeRegex.test(code)) return false;
    if (!name || name === "") return false;
    if (!type || type === "") return false;
    if (!processSelection || processSelection === "") return false;

    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "") : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "type" && value === "O") {
      // si se setea tipo Otros, setear Determinación de colaboradores habilitados
      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        "processSelection": "H",
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
